<template>
	<div class="section_container theme_user">
		<div class="section_header">
			<h1 class="logo">
				<img src="@/assets/images/logo_do.png" alt="다우오피스" title="다우오피스" />
				<span class="txt">멤버십 스토어</span>
			</h1>
		</div>
		<div class="section_body">
			<base-side-bar></base-side-bar>
			<div class="section_content">
				<content-top></content-top>
				<!--container-->
				<router-view />
				<!--//container-->
			</div>

			<side-bar id="sideBar" v-on:openModal="openModal" :type="'ADDEXTENSION'" v-if="isView()"></side-bar>
		</div>
		<base-footer></base-footer>
	</div>
</template>

<script>
import BaseSideBar from '@/components/common/BaseSideBar.vue';
import ContentTop from '@/components/common/ContentTop.vue';
import SideBar from '@/components/apply/user/store/SideBar.vue';
import ModalComponent from '@/components/code/modal/ModalComponent';
import BaseFooter from '@/components/common/BaseFooter.vue';
export default {
	data() {
		return {
			modalData: null,
		};
	},
	components: {
		BaseSideBar,
		ContentTop,
		SideBar,
		BaseFooter,
	},
	created() {
		document.title = '다우오피스 멤버십 스토어';
		var url = document.location.href;
		window.addEventListener('scroll', this.detectWindowScrollY);
	},
	methods: {
		isView() {
			return this.$route.path.indexOf('/order/addExtension') > -1 ? true : false;
		},
		openModal(modalData) {
			this.$modal.show(
				ModalComponent,
				{
					item: modalData,
				},
				{
					width: '560px',
					height: '600px',
					draggable: true,
				},
				{
					closed: this.closeModal,
				},
			);
			this.modalData = modalData;
			this.modalFlag = true;
		},
		closeModal() {
			this.modalFlag = false;
			this.modalData = null;
		},
	},
};
</script>
<style></style>
